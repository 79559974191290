import './uhoh.css'

function UhOh(){
    return (
        <div id="uhoh-image-container">
            <img id="uhohImage" src="404Test2.png"></img>
        </div>
    )
}

export default UhOh
